<template>
  <div class="forget-pwd-container">
    <div class="certification-main">
      <p class="certification-main-title">验证账户</p>
      <van-form @submit="onSubmit">
        <p class="forget-pwd-phone">手机号码：{{phoneNumHide}}</p>
        <div
          v-if="isCaptcha"
          @click="getSms"
          style="margin-left: 5%;margin-bottom: 16px;margin-top: 16px;"
          class="right-slider-black-container"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>
        <div class="cer-sms-container">
          <van-field
            autocomplete="off"
            v-model="sms"
            class="login-sms"
            type="digit"
            name="validatorSms"
            label="验证码"
            :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="disabled"
            @click="getSms"
          >
            {{ text }}
          </button>
        </div>
        <div style="margin: 70px 1.125rem 1rem 1.375rem;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >下 一 步</van-button>
        </div>
      </van-form>

    </div>
  </div>
</template>
<script>
import { create } from '../lib/captcha.js'
import { captchaAppKey } from '../lib/index.js'
import Vue from 'vue';
import md5 from 'js-md5';
import Cookies from 'js-cookie'
import api from '../api/index-client'
import { Form,Field,Button } from 'vant';
const config = require('../config')

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
export default {
  data () {
    return {
      isCaptcha: false,
      phoneNum: '',
      phoneNumHide: '',
      username: '',
      idCard: '',
      time: null,
      sms: '',
      disabled: false,
      text: '获取验证码',
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getMyInfo() // 获取个人资料
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.phoneNum = result.data.data.phone
            this.phoneNumHide = String(this.phoneNum).substring(0,3) + '****' + String(this.phoneNum).substring(String(this.phoneNum).length - 4,)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    validatorSms (val) {
      return /^\d{6}$/.test(val);
    },
    getSms () {
      this.isCaptcha = true
      this.$nextTick(() => {
        this.captcha = create('login-main-right-slider',data => {
          var timestamp = new Date().getTime()
          var nonce = md5(String(timestamp))
          var signStr = 'appId=' + config.appId + '&mobile=' + this.phoneNum + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
          var sign = md5(signStr)
          api
            .post('afs/sms',{ phone: this.phoneNum,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
            .then(result => {
              if (result.data.success) {
                this.time = 60;
                this.timer();
                this.isCaptcha = false
                this.$toast.success('验证码发送成功')
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
            })
        })
        this.captcha.reset()
        this.captcha.show()
      })


    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "发送验证码";
        this.disabled = false;
      }
    },
    onSubmit (values) {
      api
        .post('verify',{ "mobile": this.phoneNum,"code": this.sms,from: 'h5',deviceNo: 'h5' })
        .then(result => {

          if (result.data.success && result.data.data) {
            this.$toast.success('成功')
            this.$router.replace('/resetPwd?code=' + this.sms)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.forget-pwd-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .certification-main {
    padding-top: 1.3125rem;

    .certification-main-title {
      text-align: left;
      width: 90%;
      margin-left: 5%;
      font-size: 22px;
      font-family: lantingheiweight;
      font-weight: 300;
      color: #ffffff;
      line-height: 31px;
      letter-spacing: 1px;
      margin-bottom: 1.3125rem;
    }

    .van-form {
      .forget-pwd-phone {
        width: 90%;
        margin-left: 5%;
        text-align: left;
        font-size: 14px;
        font-family: lantingheiweight;
        font-weight: 400;
        color: #b3b3b3;
        line-height: 19px;
      }
      .van-cell::after {
        display: none;
      }

      .login-sms {
        margin-top: 16px;
        width: 90%;
        margin-left: 5%;
        height: 52px;
        background: #313131;
        border-radius: 4px;
        padding: 1rem 0.8125rem;

        .van-cell__title,
        .van-cell__value {
          font-size: 0.875rem;
          font-family: lantingheiweight;
          font-weight: normal;
          color: #b3b3b3 !important;
          line-height: 1.1875rem;
        }

        /deep/.van-field__control {
          font-size: 0.875rem;
          font-family: lantingheiweight;
          font-weight: 400;
          color: #ffffff !important;
          line-height: 1.1875rem;
        }
        /deep/.van-field__label {
          font-size: 14px;
          font-family: lantingheiweight;
          font-weight: 400;
          color: #b3b3b3;
          line-height: 19px;
        }
        .van-field__error-message {
          position: absolute;
          top: 1rem;
        }
      }

      .van-button {
        height: 2.875rem;
        position: relative;
        background-image: url('../assets/icon/confirm-button.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: 100% auto;
        background-color: transparent;
        font-size: 1rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #ffffff;
        line-height: 1.375rem;
        border-radius: 0;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .certification-tip-list {
      .certification-tip-title {
        font-size: 0.875rem;
        font-weight: 500;
        color: #999999;
        line-height: 1.1875rem;
      }

      .certification-tip {
        font-size: 0.8125rem;
        font-weight: 400;
        color: #999999;
        line-height: 1.1875rem;
      }
    }
  }
  .cer-sms-container {
    position: relative;
    .van-cell__value {
      input {
        width: 6.25rem;
      }
    }
    button {
      position: absolute;
      bottom: 1rem;
      right: 0.625rem;
      background: none;
      border: none;
      outline: none;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 400;
      color: #8d8d8d;
      line-height: 19px;
      margin-right: 25px;
    }
  }
}
</style>